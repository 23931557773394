import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

const Home = Loadable(lazy(() => import("views/home")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/home",
      element: <Home />,
    },
  ],
};

export default MainRoutes;
