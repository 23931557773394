import NavigationScroll from "layout/NavigationScroll";
import { RouterProvider } from "react-router-dom";
import router from "routes";
import ThemeCustomization from "themes";

function App() {
  return (
    <ThemeCustomization>
      <NavigationScroll>
        <RouterProvider router={router} />
      </NavigationScroll>
    </ThemeCustomization>
  );
}

export default App;
