import { Navigate, createBrowserRouter } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import ProductRoutes from './ProductRoutes';
import ScrollToTop from 'ui-component/ScrollToTop';

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter([
  {
    path: '*',
    element: <Navigate to="/home" />,
  },
  {
    path: '/',
    element: <Navigate to="/home" />,
  },
  {
    ...MainRoutes,
    element: (
      <>
        <ScrollToTop />
        {MainRoutes.element}
      </>
    ),
  },
  {
    ...ProductRoutes,
    element: (
      <>
        <ScrollToTop />
        {ProductRoutes.element}
      </>
    ),
  },
]);

export default router;
