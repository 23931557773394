import styled from "@emotion/styled";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import Logo1000 from "assets/images/1000-black.png";
import LogoStartup from "assets/images/startup.png";
import FooterBg1 from "assets/svg/footer-bg1.svg";
import { ReactComponent as InstagramIcon } from "assets/svg/instagram.svg";
import { ReactComponent as LinkedInIcon } from "assets/svg/linkedIn.svg";
import { ReactComponent as RightArrow } from "assets/svg/right-arrow.svg";
import PCILogo from "assets/svg/PciDss.png";
import NDPRLogo from "assets/svg/NDPR_Complaint.jpg";
import { ReactComponent as XIcon } from "assets/svg/x.svg";
import { Link, useLocation } from "react-router-dom";
import PrimaryButton from "ui-component/button/PrimaryButton";
import WhiteButton from "ui-component/button/WhiteButton";
import LightLogo from "ui-component/LightLogo";
import SectionBox from "ui-component/SectionBox";
import Overview from "./Overview";
import UseCases from "./UseCases";
import Product from "./Product";
import Developer from "./Developer";

const FooterWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.custom.darkColor,
  display: "flex",
  flexDirection: "column",
  position: "relative",

  [theme.breakpoints.down("md")]: {},
}));

const TopFooterWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10, 0),
  position: "relative",
  zIndex: 1,
  ":before": {
    content: `""`,
    backgroundImage: `url(${FooterBg1})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,

    [theme.breakpoints.down("sm")]: {
      backgroundSize: "contain",
      backgroundPosition: "center",
    },
  },
}));

const MainFooterContent = styled(Box)(({ theme }) => ({}));

const LogoBox = styled(Box)(({ theme }) => ({
  borderTop: "1px solid #FFFFFF0D",
  borderBottom: "1px solid #FFFFFF0D",
  padding: theme.spacing(5, 0),
}));

const BackedBy1000Logo = styled(Box)(({ theme }) => ({
  height: "58px",
  width: "58px",
}));

const BackedByStartup = styled(Box)(({ theme }) => ({
  height: "30px",
  width: "130px",
}));

const FooterContentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 0),
  borderBottom: "1px solid #FFFFFF0D",
}));

const SocialMediaIconWrapper = styled(IconButton)(({ theme }) => ({
  background: "#FC57191A",
  height: "40px",
  width: "40px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1),
  transition: "background-color 0.3s ease-in-out",
}));

const PrimaryArrow = styled(RightArrow)(({ theme }) => ({
  path: {
    fill: theme.palette.common.white,
  },
}));

const BottomFooter = styled(Box)(({ theme }) => ({
  borderTop: "1px solid #FFFFFF0D",
  padding: theme.spacing(2, 0),
}));

const BottomFooterNavStack = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: theme.spacing(2.5),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  padding: theme.spacing(0.2, 2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  cursor: "pointer",
  "&.active": {
    color: theme.palette.custom.primary,
    borderRadius: theme.spacing(1),
  },

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(0),
  },
}));

const NavLink = ({ to, children, onClick }) => (
  <StyledLink
    to={to}
    smooth={true}
    duration={500}
    offset={-70}
    spy={true}
    activeClass="active"
    onClick={onClick}
  >
    <Typography> {children}</Typography>
  </StyledLink>
);
const Footer = () => {
  const location = useLocation();

  return (
    <>
      <FooterWrapper>
        {location.pathname === "/home" && (
          <>
            <TopFooterWrapper>
              <Grid container>
                <Grid item xs={12}>
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={3}
                  >
                    <Typography
                      variant="f52"
                      fontWeight={600}
                      color={"common.white"}
                      textAlign={"center"}
                    >
                      Build better{" "}
                      <Typography
                        component={"span"}
                        variant="f52"
                        fontWeight={600}
                        color={"custom.primary"}
                      >
                        financial
                      </Typography>{" "}
                      product with one API
                    </Typography>

                    <Typography
                      variant="f16"
                      fontWeight={500}
                      color={"text.white60"}
                      textAlign={"center"}
                      sx={{ maxWidth: 530 }}
                    >
                      E-doc offers 3rd party access to an automated KYC or
                      credit approval process with a single API.
                    </Typography>

                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={2.5}
                      mt={3}
                    >
                      <WhiteButton
                        title="View docs"
                        endIcon={<RightArrow />}
                        component={Link}
                        to="https://documenter.getpostman.com/view/28220547/2sA3rxptMQ"
                      />
                      <PrimaryButton
                        title="Talk to sales"
                        endIcon={<PrimaryArrow />}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </TopFooterWrapper>
          </>
        )}
        <MainFooterContent>
          <LogoBox>
            <SectionBox>
              <Grid container alignItems={"center"}>
                <Grid item xs={9}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                    gap={3}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <LightLogo />
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <img
                        src={PCILogo}
                        alt="PCI Logo"
                        style={{ width: "130px", height: "auto" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <img
                        src={NDPRLogo}
                        alt="PCI Logo"
                        style={{ width: "130px", height: "auto" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SectionBox>
          </LogoBox>

          <FooterContentWrapper>
            <SectionBox>
              <Grid container spacing={3}>
                <Overview />
                <Product />
                <UseCases />
                <Developer />

                <Grid item xs={12} sm={3} md={2.4}>
                  <Stack gap={3}>
                    <Typography
                      variant="f20"
                      fontWeight={600}
                      color={"common.white"}
                    >
                      Social Media{" "}
                    </Typography>

                    <Stack>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <SocialMediaIconWrapper>
                          <XIcon />{" "}
                        </SocialMediaIconWrapper>
                        <SocialMediaIconWrapper>
                          <a
                            href="https://www.instagram.com/edoconline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <InstagramIcon />
                          </a>
                        </SocialMediaIconWrapper>
                        <SocialMediaIconWrapper>
                          <a
                            href="https://www.linkedin.com/company/e-doc-online"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkedInIcon />
                          </a>
                        </SocialMediaIconWrapper>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </SectionBox>
          </FooterContentWrapper>
        </MainFooterContent>
        <BottomFooter>
          <SectionBox>
            <Grid container justifyContent={"space-between"} spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="f14"
                  fontWeight={400}
                  color={"text.white60"}
                >
                  © E-doc Online Limited. All rights reserved
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <BottomFooterNavStack>
                  <Typography
                    component={Link}
                    to={"#"}
                    variant="f16"
                    fontWeight={500}
                    color={"text.white60"}
                    sx={{
                      textDecoration: "none",
                      ":hover": { textDecoration: "underline" },
                    }}
                  >
                    Terms and Conditions
                  </Typography>{" "}
                  <Typography
                    component={Link}
                    to={"#"}
                    variant="f16"
                    fontWeight={500}
                    color={"text.white60"}
                    sx={{
                      textDecoration: "none",
                      ":hover": { textDecoration: "underline" },
                    }}
                  >
                    Privacy Policy{" "}
                  </Typography>{" "}
                </BottomFooterNavStack>
              </Grid>
            </Grid>
          </SectionBox>
        </BottomFooter>
      </FooterWrapper>
    </>
  );
};

export default Footer;
