const Typography = (theme) => ({
  fontFamily: `"Degular"`,

  f52: {
    fontSize: "52px",
    fontWeight: 600,
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    [theme.breakpoints.down("md")]: {
      fontSize: "42px",
      lineHeight: "45px",
    },
  },

  f32: {
    fontSize: "32px",
    fontWeight: 600,
    lineHeight: "40px",
    letterSpacing: "-0.01em",
    [theme.breakpoints.down("md")]: {
      fontSize: "26px",
      lineHeight: "30px",
    },
  },

  f28: {
    fontSize: "28px",
    lineHeight: "36px",
  },

  f24: {
    fontSize: "24px",
    lineHeight: "32px",
  },

  f20: {
    fontSize: "20px",
    lineHeight: "28px",
  },

  f18: {
    fontSize: "18px",
    lineHeight: "26px",
  },

  f16: {
    fontSize: "16px",
    lineHeight: "24px",
    // letterSpacing: "0.04em",
  },

  f14: {
    fontSize: "14px",
    lineHeight: "20px",
  },
});

export default Typography;
