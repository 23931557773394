import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));

const LightLogo = () => {
  return (
    <Box component={StyledLink} to="/">
      <svg
        width="144"
        height="58"
        viewBox="0 0 144 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M61.5127 57.9382C60.8042 57.9382 60.162 57.7957 59.586 57.5137C59.01 57.2303 58.5118 56.8461 58.0913 56.3598C57.6708 55.8735 57.3468 55.3267 57.1207 54.7181C56.8946 54.1095 56.7809 53.4851 56.7809 52.8419C56.7809 52.1714 56.9018 51.5326 57.1423 50.924C57.3828 50.3154 57.7183 49.7729 58.1489 49.2967C58.578 48.8204 59.082 48.442 59.658 48.1643C60.234 47.8866 60.8618 47.7471 61.543 47.7471C62.2514 47.7471 62.8937 47.8938 63.4697 48.1859C64.0457 48.478 64.541 48.8708 64.9572 49.3614C65.3733 49.8521 65.6945 50.4002 65.9205 51.0031C66.1481 51.6074 66.2604 52.2261 66.2604 52.8577C66.2604 53.5282 66.1423 54.1671 65.9061 54.7757C65.67 55.3843 65.3373 55.9267 64.9068 56.403C64.4777 56.8792 63.9737 57.2547 63.3977 57.5281C62.8217 57.8001 62.1938 57.9382 61.5127 57.9382ZM58.0697 52.8405C58.0697 53.3498 58.1518 53.8433 58.3174 54.3196C58.483 54.7958 58.7162 55.2188 59.0186 55.5872C59.321 55.9555 59.6839 56.2476 60.1102 56.4648C60.535 56.6821 61.0073 56.79 61.5271 56.79C62.0657 56.79 62.5481 56.6763 62.9729 56.4504C63.3977 56.2246 63.7591 55.9224 64.0572 55.544C64.3553 55.167 64.5813 54.7426 64.7369 54.2692C64.8924 53.7973 64.9701 53.321 64.9701 52.839C64.9701 52.3297 64.8866 51.8391 64.7225 51.3671C64.5569 50.8952 64.3207 50.4722 64.014 50.0995C63.7073 49.7269 63.3429 49.4348 62.9225 49.2219C62.502 49.0089 62.0369 48.9039 61.5271 48.9039C60.9886 48.9039 60.5076 49.0147 60.0814 49.2362C59.6566 49.4578 59.2951 49.7585 58.997 50.1355C58.699 50.5125 58.47 50.9355 58.3102 51.4031C58.1503 51.8721 58.0697 52.3513 58.0697 52.8405Z"
          fill="white"
        />
        <path
          d="M76.8386 50.1657V57.8662H75.5627V47.8147H76.6399L82.7886 55.6721V47.8291H84.063V57.8662H82.901L76.8386 50.1657Z"
          fill="white"
        />
        <path
          d="M93.2458 57.8662V47.8147H94.5217V56.7339H100.132V57.8662H93.2458Z"
          fill="white"
        />
        <path
          d="M108.321 57.8662V47.8147H109.597V57.8662H108.321Z"
          fill="white"
        />
        <path
          d="M120.38 50.1657V57.8662H119.104V47.8147H120.181L126.33 55.6721V47.8291H127.604V57.8662H126.442L120.38 50.1657Z"
          fill="white"
        />
        <path
          d="M144 56.7339V57.8662H137.172V47.8147H143.873V48.947H138.447V52.2031H143.165V53.2649H138.447V56.7339H144Z"
          fill="white"
        />
        <path
          d="M73.5784 35.9961V33.3516H82.9887V35.9961H73.5784Z"
          fill="#FC5719"
        />
        <path
          d="M86.0128 44.1557V25.1907H92.7491C94.7982 25.1907 96.509 25.6137 97.8813 26.4597C99.2536 27.3057 100.283 28.4453 100.969 29.8783C101.654 31.3114 101.998 32.9013 101.998 34.6466C101.998 36.5702 101.62 38.2436 100.862 39.668C100.105 41.0924 99.0304 42.1974 97.6408 42.9802C96.2512 43.7643 94.6197 44.1557 92.7491 44.1557H86.0128ZM98.9512 34.6466C98.9512 33.3113 98.7064 32.1315 98.2153 31.1071C97.7257 30.0826 97.0216 29.2812 96.1029 28.7028C95.1842 28.1244 94.0667 27.8352 92.7477 27.8352H89.0066V41.5111H92.7491C94.0854 41.5111 95.213 41.209 96.1317 40.6032C97.049 39.9975 97.7488 39.1788 98.2298 38.1457C98.7107 37.1141 98.9512 35.9472 98.9512 34.6466Z"
          fill="#FC5719"
        />
        <path
          d="M114.401 44.2895C113.029 44.2895 111.79 44.0219 110.684 43.4881C109.578 42.9543 108.625 42.2277 107.824 41.3112C107.022 40.3947 106.403 39.3616 105.967 38.2134C105.531 37.0652 105.312 35.8854 105.312 34.6739C105.312 33.4092 105.542 32.2035 106.007 31.0553C106.471 29.9071 107.112 28.8827 107.932 27.9835C108.752 27.0842 109.719 26.372 110.833 25.8468C111.946 25.3217 113.154 25.0583 114.454 25.0583C115.809 25.0583 117.043 25.3346 118.156 25.8857C119.27 26.4382 120.224 27.1777 121.018 28.1029C121.81 29.0295 122.425 30.0625 122.862 31.2006C123.299 32.3402 123.518 33.5071 123.518 34.6998C123.518 35.9646 123.29 37.1703 122.836 38.3199C122.381 39.4681 121.745 40.4925 120.926 41.3918C120.106 42.291 119.138 42.9989 118.025 43.5154C116.909 44.032 115.701 44.2895 114.401 44.2895ZM108.359 34.6739C108.359 35.5646 108.501 36.4279 108.786 37.2652C109.071 38.1026 109.478 38.8451 110.003 39.4954C110.529 40.1457 111.167 40.658 111.914 41.0306C112.663 41.4047 113.499 41.5918 114.427 41.5918C115.389 41.5918 116.246 41.3961 116.993 41.0047C117.742 40.6134 118.37 40.0824 118.878 39.4148C119.386 38.7472 119.774 37.999 120.041 37.1703C120.309 36.343 120.442 35.5099 120.442 34.6725C120.442 33.7819 120.303 32.9229 120.027 32.0956C119.75 31.2668 119.346 30.5287 118.81 29.8784C118.276 29.228 117.638 28.7158 116.899 28.3432C116.159 27.9691 115.335 27.782 114.427 27.782C113.465 27.782 112.61 27.9777 111.861 28.3691C111.112 28.7604 110.48 29.287 109.963 29.9446C109.446 30.6035 109.048 31.3431 108.773 32.1618C108.497 32.9819 108.359 33.8193 108.359 34.6739Z"
          fill="#FC5719"
        />
        <path
          d="M127.024 34.5128C127.024 33.356 127.228 32.2207 127.639 31.1071C128.048 29.9949 128.65 28.9791 129.443 28.0626C130.235 27.146 131.203 26.4151 132.343 25.8713C133.484 25.3288 134.775 25.0569 136.22 25.0569C137.93 25.0569 139.409 25.4439 140.658 26.2194C141.905 26.9935 142.832 27.9863 143.438 29.1978L141.085 30.7733C140.728 30.0078 140.279 29.4021 139.736 28.9561C139.192 28.5115 138.604 28.1993 137.972 28.0208C137.34 27.8424 136.721 27.7532 136.115 27.7532C135.117 27.7532 134.243 27.9532 133.495 28.3546C132.746 28.7546 132.119 29.2856 131.61 29.9431C131.102 30.6021 130.719 31.3416 130.461 32.1603C130.203 32.979 130.074 33.7991 130.074 34.6178C130.074 35.5257 130.225 36.4034 130.529 37.2494C130.831 38.0954 131.255 38.8436 131.799 39.4939C132.342 40.1443 132.988 40.6565 133.737 41.0291C134.486 41.4032 135.305 41.5903 136.197 41.5903C136.801 41.5903 137.435 41.4838 138.095 41.2694C138.754 41.0565 139.365 40.7212 139.926 40.268C140.488 39.8134 140.929 39.2134 141.25 38.4652L143.735 39.881C143.343 40.8248 142.733 41.6263 141.903 42.2852C141.075 42.9442 140.148 43.4435 139.123 43.7816C138.097 44.1197 137.087 44.2895 136.089 44.2895C134.752 44.2895 133.531 44.0089 132.425 43.4478C131.321 42.8867 130.366 42.1385 129.566 41.2032C128.763 40.268 128.14 39.2177 127.694 38.0508C127.247 36.8868 127.024 35.707 127.024 34.5128Z"
          fill="#FC5719"
        />
        <path
          d="M59.4751 28.3719V27.8352H70.3226V25.1907H56.4813V31.1761L59.4751 28.3719Z"
          fill="white"
        />
        <path
          d="M59.4751 41.5112V35.7157H68.6579V33.2308H59.4751V33.2236L56.4813 36.0279V44.1557H70.563V41.5112H59.4751Z"
          fill="#FC5719"
        />
        <path
          d="M16.906 23.9059H9.82117V16.073C9.82117 7.21133 17.037 0 25.9074 0H28.4403C37.3093 0 44.5265 7.20989 44.5265 16.073V19.2067H37.4417V16.073C37.4417 11.1148 33.404 7.0804 28.4418 7.0804H25.9059C20.9437 7.0804 16.906 11.1148 16.906 16.073V23.9059Z"
          fill="white"
        />
        <path
          d="M4.91931 28.854V28.3648C4.91931 26.9001 6.10731 25.713 7.57323 25.713H30.5484C31.7133 25.713 32.6594 24.7692 32.6594 23.6037C32.6594 22.3059 33.712 21.2542 35.0109 21.2542H44.5379C47.2351 21.2542 49.4224 23.4397 49.4224 26.1346V49.3312L45.1831 31.274C44.8288 29.9834 43.6538 28.736 42.3146 28.7403L4.91931 28.854Z"
          fill="#FC5719"
        />
        <path
          d="M25.5372 45.8665C26.3652 44.9643 26.6993 43.6377 26.4329 42.3184C26.0066 40.2119 24.1822 38.7947 22.3692 39.1616C20.5548 39.5285 19.4244 41.54 19.8507 43.6464C20.0811 44.7888 20.7348 45.7658 21.6305 46.3384C22.1071 46.6434 22.4455 47.1211 22.519 47.6823L22.9798 51.2663C23.0474 51.7901 23.4938 52.1829 24.0223 52.1829H26.79C27.6036 52.1829 28.109 51.298 27.6943 50.5973L25.4393 46.7916C25.2622 46.4952 25.3025 46.124 25.5372 45.8665Z"
          fill="white"
        />
        <path
          d="M48.5412 55.3108L43.1887 32.8078C42.954 31.8236 42.0742 31.1287 41.0618 31.1287H2.18769C0.760658 31.1287 -0.284779 32.4725 0.06946 33.8538L5.82657 56.3568C6.07424 57.3237 6.94544 57.9999 7.9448 57.9999H46.4143C47.8269 58.0014 48.8681 56.6834 48.5412 55.3108ZM29.8039 52.8504C29.6657 53.0936 29.4051 53.2447 29.1257 53.2447H22.8488C22.4571 53.2447 22.123 52.9511 22.074 52.5627L21.416 47.439C20.0955 46.7556 19.1264 45.4276 18.8096 43.8564C18.2681 41.1759 19.7701 38.6033 22.1604 38.1213C24.548 37.6407 26.934 39.4277 27.4769 42.1082C27.7966 43.6866 27.4164 45.2851 26.4603 46.4276L29.7996 52.0663C29.9393 52.3065 29.9422 52.6087 29.8039 52.8504Z"
          fill="#FC5719"
        />
      </svg>
    </Box>
  );
};

export default LightLogo;
