import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Backdrop,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { Link, scrollSpy } from "react-scroll";
import Flag from "react-world-flags";
import PrimaryButton from "ui-component/button/PrimaryButton";
import DarkLogo from "ui-component/DarkLogo";
import SectionBox from "ui-component/SectionBox";

// Styling components
const StyledDiv = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2, 0),
  width: "100%",
  borderBottom: "1px solid #0018231A",
  position: "sticky",
  top: 0,
  zIndex: 1000,
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1, 0),
  },
}));

const StyledList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(3),
  padding: 0,
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
    border: "1px solid #2727271A",
  },
}));

const StyledListItem = styled(Typography)(({ theme }) => ({
  width: "auto",
  padding: theme.spacing(0),
  position: "relative",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1, 0),
    "&:not(:last-child)": {
      borderBottom: "1px solid #2727271A",
    },
  },
}));

const DrawerContent = styled(Box)(({ theme }) => ({
  width: "80%",
  maxWidth: 300,
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  borderTopRightRadius: theme.spacing(3),
  borderBottomRightRadius: theme.spacing(3),
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  overflowY: "auto",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  padding: theme.spacing(0.2, 2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  cursor: "pointer",
  "&.active": {
    color: theme.palette.custom.primary,
    borderRadius: theme.spacing(1),
  },

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(0),
  },
}));

const StyledLinkNavigate = styled(RouterLink)(({ theme }) => ({
  textDecoration: "none",
  padding: theme.spacing(0.2, 2),
  color: "#000",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  cursor: "pointer",
  "&.active": {
    color: theme.palette.custom.primary,
    borderRadius: theme.spacing(1),
  },

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(0),
  },
}));

const NavLink = ({ to, children, onClick }) => (
  <StyledLink
    to={to}
    smooth={true}
    duration={500}
    offset={-70}
    spy={true}
    activeClass="active"
    onClick={onClick}
  >
    <Typography> {children}</Typography>
    <ChevronRightIcon sx={{ display: { xs: "block", md: "none" } }} />
  </StyledLink>
);

// Dropdown menu items
const products = [
  { name: "E-Link", description: "Access bank account data", to: "/e-link" },
  {
    name: "E-Risk",
    description: "Lending and credit assessment",
    to: "/e-risk",
  },
  { name: "E-Check", description: "Verify Income Data", to: "/e-check" },
  {
    name: "E-BankPay",
    description: "Setup instant and recurring bank payments",
    to: "/e-direct-pay",
  },
  {
    name: "E-Ronci",
    description: "Reconcile your financial data across banks",
    to: "/e-ronci",
  },
];

const NavList = ({ toggleDrawer }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledListItem>
        <NavLink to="about-us" onClick={toggleDrawer(false)}>
          About us
        </NavLink>
      </StyledListItem>

      <StyledListItem>
        <NavLink to="#" onClick={handleClick}>
          Products
        </NavLink>
      </StyledListItem>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
          sx: {
            padding: "20px",
          },
        }}
        sx={{
          maxWidth: "90%",
        }}
      >
        <Grid container spacing={2}>
          {products.map((product, index) => (
            <Grid item sm={6} md={4} key={index}>
              <MenuItem
                onClick={() => {
                  navigate(product.to);
                  handleClose();
                }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <Typography variant="f18" fontWeight={600}>
                  {product.name}
                </Typography>
                <Typography variant="f14" sx={{ textWrap: "wrap" }}>
                  {product.description}
                </Typography>
              </MenuItem>
            </Grid>
          ))}
        </Grid>
      </Menu>

      <StyledListItem>
        <NavLink to="use-case" onClick={toggleDrawer(false)}>
          Use Case
        </NavLink>
      </StyledListItem>
      <StyledListItem>
        <NavLink to="faqs" onClick={toggleDrawer(false)}>
          FAQs
        </NavLink>
      </StyledListItem>
    </>
  );
};

const SignInButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <PrimaryButton
        variant="contained"
        color="secondary"
        title="Connect"
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem component={RouterLink} to={"https://connect.e-doconline.ng"}>
          <Flag code="NGA" style={{ width: "20px", marginRight: "10px" }} />
          Nigeria
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to={"https://connect.e-doconline.co.uk"}
        >
          <Flag code="GBR" style={{ width: "20px", marginRight: "10px" }} />
          United kingdom
        </MenuItem>
      </Menu>
    </>
  );
};

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [slideDirection, setSlideDirection] = useState("right");
  window.scrollTo({ top: 0, behavior: "smooth" });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open) {
      setSlideDirection("right");
      document.body.style.overflow = "hidden";
    } else {
      setSlideDirection("right");
      document.body.style.overflow = "auto";
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerOpen &&
        !event.target.closest("#drawer-content") &&
        !event.target.closest("#menu-button")
      ) {
        setSlideDirection("right");
        setDrawerOpen(false);
        document.body.style.overflow = "auto";
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerOpen]);

  useEffect(() => {
    setTimeout(() => {
      scrollSpy.update();
    }, 100);
  }, []);

  const handleLogoClick = () => {
    if (location.pathname === "/home") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/home");
    }
  };

  return (
    <StyledDiv>
      <SectionBox>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            gap={2}
          >
            <MenuButton onClick={toggleDrawer(true)} id="menu-button">
              <MenuIcon />
            </MenuButton>
            <Box onClick={handleLogoClick} style={{ cursor: "pointer" }}>
              <DarkLogo />
            </Box>
          </Stack>
          <StyledList>
            <NavList toggleDrawer={toggleDrawer} />
          </StyledList>
          <SignInButton />
        </Stack>
      </SectionBox>

      <Backdrop
        open={drawerOpen}
        onClick={toggleDrawer(false)}
        sx={{ zIndex: theme.zIndex.drawer }}
      />
      <Slide
        direction={slideDirection}
        in={drawerOpen}
        mountOnEnter
        unmountOnExit
      >
        <DrawerContent id="drawer-content">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            padding={2}
            sx={{
              borderBottom: "1px solid #0018231A",
            }}
          >
            <Box onClick={handleLogoClick} style={{ cursor: "pointer" }}>
              <DarkLogo />
            </Box>
            <MenuButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </MenuButton>
          </Stack>

          <Box
            sx={{
              background: "#FFFFFFE5",
              flexGrow: 1,
              padding: 2,
            }}
          >
            <Typography variant="f24" fontWeight={600}>
              Menu
            </Typography>
            <Box>
              <NavList toggleDrawer={toggleDrawer} />
            </Box>
          </Box>
        </DrawerContent>
      </Slide>
    </StyledDiv>
  );
};

export default Header;
