import { createTheme } from "@mui/material/styles";

const Palette = () => {
  return createTheme({
    palette: {
      background: {
        default: "#FCFAF8",
      },
      common: {
        black: "#000000",
        white: "#FFFFFF",
      },
      text: {
        primary: "#272727",
        primary40: "#27272766",
        primary50: "#21212080",
        primary60: "#27272799",
        primary80: "#272727CC",
        secondary: "#001823",
        white60: "#FFFFFF99",
      },
      custom: {
        primary: "#FC5719",
        primaryLight: "#FC57191A",
        mainColor: "#FCFAF8",
        darkColor: "#272727",
        borderColor: "#2727271A",
      },
    },
  });
};

export default Palette;
