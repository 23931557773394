import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";


const DarkLogo = () => {
  return (
    <Box>
      <svg
        width="98"
        height="40"
        viewBox="0 0 98 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M41.8627 35.0577C41.3806 35.0577 40.9435 34.9594 40.5515 34.7649C40.1595 34.5694 39.8204 34.3045 39.5343 33.9691C39.2481 33.6337 39.0276 33.2567 38.8737 32.8369C38.7199 32.4172 38.6425 31.9865 38.6425 31.543C38.6425 31.0806 38.7248 30.64 38.8884 30.2203C39.0521 29.8005 39.2804 29.4264 39.5735 29.098C39.8655 28.7695 40.2085 28.5086 40.6005 28.3171C40.9925 28.1255 41.4198 28.0293 41.8833 28.0293C42.3655 28.0293 42.8025 28.1305 43.1945 28.3319C43.5865 28.5334 43.9237 28.8043 44.2069 29.1426C44.4901 29.481 44.7086 29.8591 44.8625 30.2748C45.0173 30.6916 45.0938 31.1183 45.0938 31.5539C45.0938 32.0163 45.0134 32.4569 44.8527 32.8766C44.692 33.2963 44.4656 33.6704 44.1726 33.9989C43.8805 34.3273 43.5375 34.5863 43.1455 34.7749C42.7535 34.9624 42.3263 35.0577 41.8627 35.0577ZM39.5196 31.542C39.5196 31.8933 39.5754 32.2336 39.6881 32.5621C39.8008 32.8905 39.9596 33.1822 40.1654 33.4363C40.3712 33.6903 40.6181 33.8917 40.9082 34.0416C41.1973 34.1914 41.5187 34.2658 41.8725 34.2658C42.239 34.2658 42.5673 34.1874 42.8564 34.0316C43.1455 33.8758 43.3915 33.6675 43.5944 33.4065C43.7972 33.1465 43.9511 32.8538 44.0569 32.5273C44.1628 32.2019 44.2157 31.8734 44.2157 31.541C44.2157 31.1897 44.1589 30.8514 44.0471 30.5259C43.9344 30.2004 43.7737 29.9087 43.565 29.6517C43.3562 29.3947 43.1083 29.1932 42.8221 29.0464C42.536 28.8995 42.2194 28.8271 41.8725 28.8271C41.506 28.8271 41.1787 28.9035 40.8886 29.0563C40.5995 29.2091 40.3535 29.4165 40.1507 29.6765C39.9478 29.9365 39.792 30.2282 39.6832 30.5507C39.5744 30.8742 39.5196 31.2046 39.5196 31.542Z"
          fill="#001823"
        />
        <path
          d="M52.2928 29.6976V35.0083H51.4246V28.0762H52.1576L56.3422 33.495V28.0861H57.2095V35.0083H56.4186L52.2928 29.6976Z"
          fill="#001823"
        />
        <path
          d="M63.459 35.0083V28.0762H64.3273V34.2273H68.1453V35.0083H63.459Z"
          fill="#001823"
        />
        <path
          d="M73.7185 35.0083V28.0762H74.5868V35.0083H73.7185Z"
          fill="#001823"
        />
        <path
          d="M81.925 29.6976V35.0083H81.0568V28.0762H81.7898L85.9744 33.495V28.0861H86.8417V35.0083H86.0508L81.925 29.6976Z"
          fill="#001823"
        />
        <path
          d="M97.9999 34.2273V35.0083H93.3528V28.0762H97.9137V28.8571H94.2211V31.1026H97.4315V31.8349H94.2211V34.2273H97.9999Z"
          fill="#001823"
        />
        <path
          d="M50.0741 19.9254V18.1016H56.4784V19.9254H50.0741Z"
          fill="#FC5719"
        />
        <path
          d="M58.5364 25.5525V12.4731H63.1208C64.5153 12.4731 65.6796 12.7649 66.6135 13.3483C67.5475 13.9318 68.2482 14.7177 68.7146 15.706C69.1811 16.6943 69.4153 17.7908 69.4153 18.9944C69.4153 20.3211 69.1576 21.4752 68.6421 22.4575C68.1266 23.4399 67.3956 24.202 66.4499 24.7418C65.5042 25.2825 64.3938 25.5525 63.1208 25.5525H58.5364ZM67.3417 18.9944C67.3417 18.0736 67.1751 17.2599 66.8409 16.5534C66.5077 15.8469 66.0285 15.2942 65.4032 14.8953C64.778 14.4964 64.0175 14.297 63.1198 14.297H60.5738V23.7286H63.1208C64.0302 23.7286 64.7976 23.5203 65.4228 23.1025C66.0471 22.6847 66.5234 22.1201 66.8507 21.4077C67.178 20.6962 67.3417 19.8915 67.3417 18.9944Z"
          fill="#FC5719"
        />
        <path
          d="M77.856 25.6447C76.9221 25.6447 76.0793 25.4602 75.3267 25.092C74.574 24.7239 73.9253 24.2228 73.3804 23.5907C72.8345 22.9586 72.4131 22.2461 72.1162 21.4543C71.8192 20.6625 71.6703 19.8488 71.6703 19.0133C71.6703 18.1411 71.8271 17.3095 72.1436 16.5177C72.4592 15.7258 72.8953 15.0193 73.4539 14.3992C74.0115 13.779 74.6701 13.2878 75.4276 12.9256C76.1851 12.5634 77.0074 12.3818 77.8923 12.3818C78.8145 12.3818 79.6543 12.5724 80.4119 12.9524C81.1694 13.3334 81.8191 13.8435 82.3591 14.4815C82.8981 15.1205 83.3166 15.833 83.6145 16.6179C83.9114 17.4038 84.0604 18.2085 84.0604 19.0311C84.0604 19.9034 83.9056 20.7349 83.5969 21.5277C83.2872 22.3196 82.854 23.0261 82.2964 23.6463C81.7388 24.2664 81.0802 24.7546 80.3227 25.1109C79.5632 25.4671 78.741 25.6447 77.856 25.6447ZM73.744 19.0133C73.744 19.6275 73.841 20.2229 74.035 20.8004C74.2291 21.3779 74.5054 21.8899 74.8631 22.3384C75.2208 22.7869 75.655 23.1402 76.1636 23.3972C76.6732 23.6552 77.2426 23.7842 77.8737 23.7842C78.5283 23.7842 79.1114 23.6492 79.62 23.3793C80.1296 23.1094 80.5569 22.7433 80.9028 22.2829C81.2488 21.8224 81.5124 21.3064 81.6947 20.7349C81.877 20.1643 81.9671 19.5898 81.9671 19.0123C81.9671 18.3981 81.873 17.8057 81.6849 17.2351C81.4967 16.6635 81.2213 16.1545 80.8568 15.706C80.4932 15.2575 80.0591 14.9042 79.5563 14.6472C79.0526 14.3892 78.4921 14.2602 77.8737 14.2602C77.219 14.2602 76.6369 14.3952 76.1273 14.6651C75.6177 14.935 75.1875 15.2982 74.8357 15.7516C74.4839 16.2061 74.2134 16.7161 74.0262 17.2807C73.838 17.8463 73.744 18.4239 73.744 19.0133Z"
          fill="#FC5719"
        />
        <path
          d="M86.4467 18.9022C86.4467 18.1044 86.5858 17.3214 86.8651 16.5534C87.1434 15.7864 87.5531 15.0858 88.093 14.4537C88.632 13.8217 89.2906 13.3176 90.0668 12.9425C90.8429 12.5684 91.722 12.3809 92.7049 12.3809C93.8692 12.3809 94.8756 12.6478 95.7253 13.1826C96.5739 13.7165 97.2051 14.4012 97.6176 15.2367L96.0163 16.3232C95.7733 15.7953 95.4675 15.3776 95.0981 15.07C94.7276 14.7633 94.3278 14.548 93.8976 14.425C93.4674 14.3019 93.046 14.2404 92.6334 14.2404C91.9542 14.2404 91.3594 14.3783 90.8508 14.6552C90.3412 14.931 89.9139 15.2972 89.568 15.7507C89.222 16.2051 88.9613 16.7152 88.7859 17.2798C88.6105 17.8444 88.5223 18.41 88.5223 18.9746C88.5223 19.6007 88.6252 20.206 88.832 20.7895C89.0378 21.3729 89.3259 21.8889 89.6963 22.3374C90.0658 22.786 90.5058 23.1392 91.0154 23.3962C91.525 23.6542 92.0826 23.7832 92.6892 23.7832C93.1008 23.7832 93.532 23.7098 93.9809 23.5619C94.4297 23.4151 94.8452 23.1839 95.2274 22.8713C95.6096 22.5577 95.9095 22.144 96.1281 21.628L97.8195 22.6044C97.553 23.2553 97.1374 23.808 96.573 24.2625C96.0095 24.7169 95.3784 25.0613 94.6806 25.2945C93.9828 25.5276 93.2949 25.6447 92.6157 25.6447C91.7063 25.6447 90.8753 25.4512 90.1226 25.0642C89.371 24.6773 88.7212 24.1613 88.1764 23.5163C87.6305 22.8713 87.2062 22.1469 86.9024 21.3422C86.5986 20.5394 86.4467 19.7258 86.4467 18.9022Z"
          fill="#FC5719"
        />
        <path
          d="M40.476 14.6671V14.297H47.8583V12.4731H38.4386V16.6011L40.476 14.6671Z"
          fill="#001823"
        />
        <path
          d="M40.476 23.7287V19.7318H46.7255V18.0181H40.476V18.0132L38.4386 19.9471V25.5526H48.022V23.7287H40.476Z"
          fill="#FC5719"
        />
        <path
          d="M11.5054 16.4868H6.68384V11.0848C6.68384 4.97333 11.5946 0 17.6314 0H19.3552C25.391 0 30.3028 4.97234 30.3028 11.0848V13.246H25.4812V11.0848C25.4812 7.6654 22.7333 4.88303 19.3562 4.88303H17.6304C14.2533 4.88303 11.5054 7.6654 11.5054 11.0848V16.4868Z"
          fill="#001823"
        />
        <path
          d="M3.3479 19.8995V19.5621C3.3479 18.5519 4.1564 17.7333 5.15404 17.7333H20.7899C21.5827 17.7333 22.2266 17.0824 22.2266 16.2786C22.2266 15.3836 22.943 14.6582 23.8269 14.6582H30.3106C32.1461 14.6582 33.6347 16.1655 33.6347 18.024V34.0217L30.7496 21.5685C30.5085 20.6784 29.7089 19.8181 28.7975 19.8211L3.3479 19.8995Z"
          fill="#FC5719"
        />
        <path
          d="M17.3796 31.6322C17.9431 31.01 18.1704 30.0951 17.9891 29.1852C17.699 27.7325 16.4574 26.7551 15.2236 27.0082C13.9888 27.2612 13.2195 28.6484 13.5096 30.1011C13.6664 30.889 14.1113 31.5627 14.7208 31.9577C15.0452 32.168 15.2755 32.4975 15.3255 32.8845L15.6391 35.3562C15.6852 35.7174 15.989 35.9883 16.3486 35.9883H18.2322C18.7859 35.9883 19.1298 35.3781 18.8476 34.8948L17.3129 32.2702C17.1924 32.0658 17.2198 31.8098 17.3796 31.6322Z"
          fill="#001823"
        />
        <path
          d="M33.035 38.1456L29.3923 22.6263C29.2326 21.9475 28.6338 21.4683 27.9449 21.4683H1.48885C0.51767 21.4683 -0.193808 22.3951 0.0472714 23.3476L3.9653 38.867C4.13386 39.5338 4.72676 40.0002 5.40688 40.0002H31.5875C32.5489 40.0012 33.2574 39.0922 33.035 38.1456ZM20.2832 36.4488C20.1892 36.6165 20.0118 36.7207 19.8217 36.7207H15.5499C15.2833 36.7207 15.0559 36.5182 15.0226 36.2503L14.5748 32.7168C13.6761 32.2455 13.0166 31.3296 12.801 30.246C12.4325 28.3974 13.4546 26.6232 15.0814 26.2908C16.7063 25.9593 18.3301 27.1918 18.6996 29.0404C18.9171 30.1289 18.6584 31.2314 18.0077 32.0192L20.2803 35.908C20.3754 36.0737 20.3773 36.2821 20.2832 36.4488Z"
          fill="#FC5719"
        />
      </svg>
    </Box>
  );
};

export default DarkLogo;
