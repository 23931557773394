import { lazy } from "react";

// project imports
import ProductLayout from "layout/ProductLayout";
import Loadable from "ui-component/Loadable";

const ELink = Loadable(lazy(() => import("views/products/eLink")));
const ERisk = Loadable(lazy(() => import("views/products/eRisk")));
const ECheck = Loadable(lazy(() => import("views/products/eCheck")));
const EDirectPay = Loadable(lazy(() => import("views/products/eDirectPay")));
const ERonci = Loadable(lazy(() => import("views/products/eRonci")));

const ProductRoutes = {
  path: "/",
  element: <ProductLayout />,
  children: [
    {
      path: "/e-link",
      element: <ELink />,
    },
    {
      path: "/e-risk",
      element: <ERisk />,
    },
    {
      path: "/e-check",
      element: <ECheck />,
    },
    {
      path: "/e-direct-pay",
      element: <EDirectPay />,
    },
    {
      path: "/e-ronci",
      element: <ERonci />,
    },
  ],
};

export default ProductRoutes;
