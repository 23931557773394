import { Box, CssBaseline, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "layout/Common/Footer";
import Header from "layout/Common/Header";

const Main = styled("main")(({ theme }) => ({
  width: "100%",
  flexGrow: 1,
  overflow: "auto",
  // padding: theme.spacing(2, 0),
}));

const MainLayout = () => {
  return (
    <Box>
      <CssBaseline />
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </Box>
  );
};

export default MainLayout;
